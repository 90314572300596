import React, { useState } from 'react';
import './Form.css'; // Optional: Include a CSS file for styling
import logo from './Logo.png';
import db from './firebase'; // Import the Firestore instance
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions


function Form() {
    const initialState = {
        kipaDays: 1,
        timesPerDay: 1,
        easeOfUse: 1,
        taskSatisfaction: '',
        timeSaved: '',
        reasonForUse: '',
        reasonForNonUse: '',
        licenseCost: '',
        improvementSuggestions: '',
        additionalComments: ''
      };
    
      const [formData, setFormData] = useState(initialState);

// Handle form submission
const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
        // Add a new document with form data to Firestore
        const docRef = await addDoc(collection(db, 'Feedback'), formData);
        console.log('Document written with ID: ', docRef.id);
        alert('Form submitted successfully!');

        // Reset form fields to initial state
        setFormData(initialState);
    } catch (e) {
        console.error('Error adding document: ', e);
        alert('Error submitting the form. Please try again.');
    }
};

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="page">
      <div className="contact-page">
        <h1>Feedback Form</h1>
        <form onSubmit={handleSubmit} className="form-container">
          <label>
            Hvor mange dager i uka bruker du KIPA? (1-7):
            <input
              type="range"
              name="kipaDays"
              min="1"
              max="7"
              value={formData.kipaDays}
              onChange={handleChange}
              required
            />
            <span>{formData.kipaDays}</span>
          </label>
          <label>
            Hvor mange ganger per dag? (1-20):
            <input
              type="range"
              name="timesPerDay"
              min="1"
              max="20"
              value={formData.timesPerDay}
              onChange={handleChange}
              required
            />
            <span>{formData.timesPerDay}</span>
          </label>
          <label>
            Hvor enkelt er det å bruke KIPA? (1-10) der 10 er super lett:
            <input
              type="range"
              name="easeOfUse"
              min="1"
              max="10"
              value={formData.easeOfUse}
              onChange={handleChange}
              required
            />
            <span>{formData.easeOfUse}</span>
          </label>
          <label>
            Hvordan synes du programmet løser de oppgaver du tenkte den skulle gjøre?
            <textarea
              name="taskSatisfaction"
              value={formData.taskSatisfaction}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Hvor mye tid sparer du på jobb med å bruke dette programmet? Pr. uke?
            <input
              type="text"
              name="timeSaved"
              value={formData.timeSaved}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Hvorfor bruker du KIPA?
            <textarea
              name="reasonForUse"
              value={formData.reasonForUse}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Hvorfor bruker du ikke KIPA?
            <textarea
              name="reasonForNonUse"
              value={formData.reasonForNonUse}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Hva synes du at lisens på et slikt program kan koste?
            <input
              type="text"
              name="licenseCost"
              value={formData.licenseCost}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Forslag til forbedringer?
            <textarea
              name="improvementSuggestions"
              value={formData.improvementSuggestions}
              onChange={handleChange}
            />
          </label>
          <label>
            Er det noe annet du vil kommentere?
            <textarea
              name="additionalComments"
              value={formData.additionalComments}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Send inn</button>
        </form>
      </div>
      <footer className="App-footer3">
        <div className="footer-content">
          <div className="App-footer-left">
            <p>KONTAKT OSS: (+47) 46942484 | amdevelopment@aihr.no</p>
          </div>
          <div className="App-footer-center">
            <img src={logo} className="App-logo-footer" alt="logo" />
          </div>
          <div className="App-footer-right">
            <p>© 2024 Am-Development AS</p>
            <p>Organisasjonsnummer: 933 826 759</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Form;
