import React from 'react';
import './About.css'; // Updated CSS file name for consistency
import logo from './Logo.png';

function About() {
  return (
    <div className="page">
      <div className="about-page">
        <h1>Om oss</h1>
        <div className="about-info">
          <p>
          Velkommen til Am-Development AS. Vi er et nyoppstartet selskap som er dedikert til å levere innovative løsninger innen utvikling og teknologi. Vårt mål er å styrke bedrifter med teknologi som fremmer vekst og effektivitet.
          </p>

          <div className="about-detail">
            <h2>Vår visjon</h2>
            <p>
            Hos Am-Development er vår visjon å utnytte moderne teknologi for å skape transformative løsninger for våre kunder. Vi ønsker å tilby teknologisk innovasjon som hjelper bedrifter med å navigere i det digitale landskapet på en enkel og effektiv måte.
            </p>
          </div>

          <div className="about-detail">
            <h2>Vårt Team</h2>
            <p>
            Vi er et dedikert team med ekspertise innen teknologi og utvikling. Med vår kombinerte erfaring og lidenskap for vårt arbeid, jobber vi tett sammen for å utvikle løsninger som møter kundenes behov og overgår deres forventninger.
            </p>
          </div>
        </div>
      </div>

      <footer className="App-footer2">
        <div className="footer-content">
          <div className="App-footer-left">
            <p>KONTAKT OSS: (+47) 46942484 | amdevelopment@aihr.no</p>
          </div>
          <div className="App-footer-center">
            <img src={logo} className="App-logo-footer" alt="logo" />
          </div>
          <div className="App-footer-right">
            <p>© 2024 Am-Development AS</p>
            <p>Organisasjonsnummer: 933 826 759</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default About;
