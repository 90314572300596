import React from 'react';
import { jsPDF } from 'jspdf';
import './Invoice.css'; // Update the CSS file to match the new component name

function Invoice({ cart, buyerInfo, orderDetails }) { // Added orderNumber as a prop
  const sellerInfo = {
    name: "AM-Development AS",
    orgNumber: "933 826 759",
    address: "Kjeldsbergveien 2, 9513 Alta",
    orderDate: new Date().toLocaleDateString(), // Current date
  };

  // Calculate the total price considering the quantity of each license
  const totalAmount = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Function to download the order confirmation as a PDF and upload to Google Drive
  const downloadOrderConfirmation = async () => {
    const doc = new jsPDF();

    // Add seller info
    doc.text(`Selger: ${sellerInfo.name}`, 10, 10);
    doc.text(`Org.nr: ${sellerInfo.orgNumber}`, 10, 20);
    doc.text(`Adresse: ${sellerInfo.address}`, 10, 30);

    // Add buyer info
    doc.text(`Kjøper: ${buyerInfo.name}`, 10, 60);
    doc.text(`Org.nr: ${buyerInfo.orgNumber}`, 10, 70);
    doc.text(`Adresse: ${buyerInfo.address}`, 10, 80);
    doc.text(`E-post: ${buyerInfo.epost}`, 10, 90);

    // Add order info
    doc.text(`Ordrenummer: ${orderDetails.orderNumber}`, 10, 110); // Changed from invoice number to order number
    doc.text(`Ordredato: ${sellerInfo.orderDate}`, 10, 120); // Changed from invoice date to order date

    // Add order items
    doc.text("Beskrivelse", 10, 150);
    doc.text("Pris per stk", 90, 150);
    doc.text("Antall", 140, 150);
    doc.text("Total pris", 180, 150);

    let yPosition = 160;
    cart.forEach((item) => {
      doc.text(item.name, 10, yPosition);
      doc.text(`${item.price} Kr`, 90, yPosition);
      doc.text(`${item.quantity}`, 140, yPosition);
      doc.text(`${item.price * item.quantity} Kr`, 180, yPosition);
      yPosition += 10;
    });

    // Add total amount
    doc.text(`Totalt: ${totalAmount} Kr`, 10, yPosition);

    // Add payment info
    doc.text(`Totalbeløp: ${totalAmount} Kr`, 10, yPosition + 30);

    // Save the PDF locally
    const fileName = `order_confirmation_${orderDetails.orderNumber}.pdf`;
    doc.text(`Dette er en bekreftelse på at ordren er mottatt.`, 10, yPosition + 50);
    doc.text(`Faktura kommer på e-post så snart ordren blir behandlet.`, 10, yPosition + 60);
    doc.save(fileName);

  };

  return (
    <div className="order-confirmation">
      <div className="order-confirmation-header">
        <h2>Ordrebekreftelse</h2>
        <button onClick={downloadOrderConfirmation} className="download-order-confirmation-btn">Last ned Ordrebekreftelse</button>
      </div>

      {/* Seller and Buyer Info */}
      <div className="info-container">
        <div className="seller-info">
          <h3>Selger:</h3>
          <p>{sellerInfo.name}</p>
          <p>Org.nr: {sellerInfo.orgNumber}</p>
          <p>{sellerInfo.address}</p>
        </div>

        <div className="buyer-info">
          <h3>Kjøper:</h3>
          <p>{buyerInfo.name}</p>
          <p>Org.nr: {buyerInfo.orgNumber}</p>
          <p>{buyerInfo.address}</p>
          <p>{buyerInfo.epost}</p>
        </div>
      </div>

      {/* Order Info */}
      <div className="order-details">
        <p>Ordrenummer: {orderDetails.orderNumber}</p> {/* Display the order number */}
        <p>Ordredato: {sellerInfo.orderDate}</p>
      </div>

      {/* Order Items */}
      <table className="order-table">
        <thead>
          <tr>
            <th>Beskrivelse</th>
            <th>Pris per stk</th>
            <th>Antall</th>
            <th>Total pris</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.price} Kr</td>
              <td>{item.quantity}</td>
              <td>{item.price * item.quantity} Kr</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">Totalt</td>
            <td>{totalAmount} Kr</td>
          </tr>
        </tfoot>
      </table>
      <div className="payment-info">
        <p>Dette er en bekreftelse på at ordren er mottatt.</p>
        <p>Faktura kommer på e-post så snart ordren blir behandlet.</p>
      </div>
    </div>
  );
}

export default Invoice;
