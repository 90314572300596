import React from 'react';
import './Contact.css'; // Include your updated CSS
import { Link } from 'react-router-dom';
import appStoreIcon from './appstore.webp';  // Replace with actual path to App Store icon
import playStoreIcon from './google-play-store-icon-logo-symbol-free-png.webp'; // Replace with actual path to Play Store icon
import { useNavigate } from 'react-router-dom';

// Import your demo images
import kipaDemoImage from './Screenshot_20240923-230315.png';
import kipaStudentDemoImage from './Screenshot_20240923-230403.png';
import aiHrDemoImage from './Screenshot_20240923-230441.png';


function Projects() {
  const navigate = useNavigate();
  const handleOrderClick = () => {
    navigate('/shopping-cart');
  };
  const projectData = [
    {
      title: 'KIPA',
      description: 'Vi har disse løsningene for deg som elev eller for deg som bedrift. KIPA er en digital løsning for alle notater og rapporter dine ansatte og du selv skal levere, for eksempel notater fra møter, utkast til møtereferat, befaringsrapporter, referat etter kundeoppfølging eller leverandørsamtaler. Man setter på opptak og "prater" inn i møtet eller etter et møte eller en befaring. Når man er ferdig genererer programmet ved hjelp av kunstig intelligens (KI) en ryddig og tydelig liste eller rapport, der irrelevant samtale ryddes vekk. Den genererte teksten kan man redigere, lese inn mer tekst i, be om å sette inn dato eller annet man kommer på, og så legge inn i eget fagsystem eller sende på e.post til mottakere.',
      link: '/project1',
      demoImage: kipaDemoImage, // Replace with actual path to demo image
      appStoreLink: 'https://apps.apple.com/app/kipa/id6503719118',  // Replace with actual App Store link
      playStoreLink: 'https://play.google.com/store/apps/details?id=com.markusliland.AIHR' // Replace with actual Play Store link
    },
    {
      title: 'KIPA-student',
      description: 'KIPA-student er en digital løsning som kan hjelpe skoleelever og studenter med å lage notater etter forelesninger, med å lage rapporter og vise sin kompetanse, uten å få hjelp fra internett. Man setter på opptak og "prater" inn etter forelesninger eller når man skal levere oppgaver. Når man er ferdig genererer programmet ved hjelp av kunstig intelligens (KI) en ryddig og tydelig liste eller rapport, med en tydelig sammenheng og der irrelevant informasjon er luket vekk. Teksten kan man redigere, lese inn mer tekst i, be om å sette inn dato eller annet man kommer på, og så lagre eller sende på e.post til læreren sin.<br /><br />På denne måten kan eleven eller studenten vise sin kompetanse uten fare for å plagiere og i samarbeid med lærestedet gjøre seg vurderbar i fag.',
      link: '/project2',
      demoImage: kipaStudentDemoImage, // Replace with actual path to demo image
      appStoreLink: 'https://apps.apple.com/app/kipa/id6503719118',  // Replace with actual App Store link
      playStoreLink: 'https://play.google.com/store/apps/details?id=com.markusliland.AIHR' // Replace with actual Play Store link
    },
    {
      title: 'AI-HR',
      description: 'AM-HR er en digital løsning for der firmaet ved hjelp av denne applikasjonen bruker kunstig intelligens (KI) til å følge opp ansatte. I AM-HR jobber KI sammen med arbeidsmiljøloven for å følge opp og dokumentere ansatte, med notater og rapporter dine ansatte og du selv skal levere. Den ansatte kan skrive inn eller "prate" inn det de lurer på eller strever med innenfor personaloppfølging eller arbeidsmiljø. Programmet generer svar til den ansatte og rapport til ansvarlig for HR. HR-ansvarlig kan følge opp kommunikasjonen ved å lese inn tilsvar eller be AM-HR om å finne svar på dette ved hjelp av KI. All personaloppfølging i AM-HR kan legges inn i eget fagsystem for personaloppfølging og kan sendes på e.post til involverte.',
      link: '/project3',
      demoImage: aiHrDemoImage, // Replace with actual path to demo image
      appStoreLink: 'https://apps.apple.com/app/kipa/id6503719118',  // Replace with actual App Store link
      playStoreLink: 'https://play.google.com/store/apps/details?id=com.markusliland.AIHR' // Replace with actual Play Store link
    }
  ];

  return (
    <div className="Ppage">
      <div className="projects-page">
        <h1>Våre Prosjekter</h1>
        <div className="projects-grid">
          {projectData.map((project, index) => (
            <div className="project-card" key={index}>
              <div className="project-info">
                <h2>{project.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: project.description }} />
                <div className="store-links">
                  <a href={project.appStoreLink} target="_blank" rel="noopener noreferrer">
                    <img src={appStoreIcon} alt="App Store" className="store-icon" />
                  </a>
                  <a href={project.playStoreLink} target="_blank" rel="noopener noreferrer">
                    <img src={playStoreIcon} alt="Google Play Store" className="store-icon" />
                  </a>
                  <button onClick={handleOrderClick} className="project-link">Bestill</button>
                </div>
              </div>
              <img src={project.demoImage} alt={`${project.title} demo`} className="project-demo-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Projects;
