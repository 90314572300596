import React, { useState, useRef, useEffect } from 'react';
import logo from './Logo.png';
import hero from './hero.png'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Import necessary components from react-router-dom
import './App.css';
import Contact from './Contact.js';
import About from './about.js';
import Form from './Form.js';
import Projects from './projects.js';
import ShoppingCart from './ShoppingCart.js';
import Support from './Support.js';


function App() {
  const [showProjects, setShowProjects] = useState(false);
  const projectSectionRef = useRef(null); // Create a reference to the project section

  // Function to toggle the project grid visibility
  const handleShowProjects = () => {
    setShowProjects(!showProjects); // Set to true to show the project section
  };

  // Scroll to the project section when showProjects is true
  useEffect(() => {
    if (showProjects && projectSectionRef.current) {
      projectSectionRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the project section
    }
  }, [showProjects]);
  return (
    <Router>
      <div className="App">
        {/* Top Bar */}
        <header className="App-topbar">
          <div className="top-content">
            <img src={logo} className="App-logo" alt="logo" />
            <nav>
              <ul className="App-nav">
                <li><Link to="/">HJEM</Link></li>
                <li><Link to="/about">OM OSS</Link></li>
                <li><Link to="/contact">KONTAKT OSS</Link></li>
                <li><Link to="/Support">SUPPORT</Link></li>
              </ul>
            </nav>
            <a href="tel:46942484" className="contactnr">46942484</a>
          </div>
        </header>

        {/* Routes */}
        <Routes>
          <Route path="/" element={
            <main>
              {/* Hero Section */}
              <section className="App-hero">
                <img src={hero} alt="Hero Image" />
              </section>

              {/* Main Content */}
              <div className="App-content">
                <header className="App-header">
                  <h1>Noen av tjenestene våre</h1>
                </header>
                <div className="service-content">
                  <div className="services-row">
                    <div className="service" onClick={handleShowProjects}>
                      <h2>Utvikling av apper</h2>
                      <p>Spesialiseringen vår omfatter utvikling av skreddersydde mobil- og webapplikasjoner som møter dine spesifikke behov. Appene våre er designet for å være brukervennlige, effektive og pålitelige, og vi sørger for en sømløs brukeropplevelse.</p>
                    </div>
                    <div className="service">
                      <h2>Programvareutvikling</h2>
                      <p>Fullstendig programvareutvikling tilbys fra konsept til ferdig produkt. Med vår erfaring sikres det at programvaren din fungerer sømløst og er lett å vedlikeholde, slik at du kan fokusere på å drive virksomheten din.</p>
                    </div>
                    <div className="service">
                      <h2>Nettstedsutvikling</h2>
                      <p>Moderne, responsive nettsider som ser bra ut og gir gode brukeropplevelser er en annen av våre styrker. Enten behovet er en enkel nettside eller en kompleks e-handelsplattform, står vi klare til å hjelpe deg med å bygge en løsning som møter dine krav.</p>
                    </div>
                    <div className="service" onClick={handleShowProjects}>
                      <h2>Implementering av kunstig intelligens</h2>
                      <p>Integrering av kunstig intelligens i dine applikasjoner og systemer er en av våre spesialiteter. Ved å utnytte KI-teknologiens fulle potensial, forbedres effektiviteten og du får et konkurransefortrinn.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Project Grid - Show/Hide based on state */}
              {showProjects && (
                <div className="projects-section" id="project" ref={projectSectionRef}>
                  <Projects /> {/* Render the Projects component when showProjects is true */}
                </div>
              )}
              <footer className="App-footer">
          <div className="footer-content">
            <div className="App-footer-left">
              <p>KONTAKT OSS: (+47) 46942484 | amdevelopment@aihr.no</p>
            </div>
            <div className="App-footer-center">
              <img src={logo} className="App-logo-footer" alt="logo" />
            </div>
            <div className="App-footer-right">
              <p>© 2024 Am-Development AS</p>
              <p>Organisasjonsnummer: 933 826 759</p>
            </div>
          </div>
        </footer>
            </main>
          } />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/Form" element={<Form />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="/Support" element={<Support />} />
          {/* Add more routes as needed */}
        </Routes>

        {/* Bottom Bar */}

      </div>
    </Router>
  );
}

export default App;
