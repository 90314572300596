import React from 'react';
import './Contact.css'; // Optional: Include a CSS file for styling
import { Link } from 'react-router-dom';
import logo from './Logo.png';

function Contact() {
  return (
    <div className="page">
    <div className="contact-page">
      <h1>Kontakt oss</h1>
      <div className="contact-info">
        <p>Om du har noen spørsmål, ta gjerne kontakt med oss:</p>
        
        <div className="contact-detail">
          <h2>Telefon</h2>
          <a href="tel:46942484" className="contactnr">46942484</a>
        </div>

        <div className="contact-detail">
          <h2>Email</h2>
          <a href="mailto:post@amdevelopmentas.no" className="contact-email">post@amdevelopmentas.no</a>
        </div>

        <div className="contact-detail">
          <h2>Adresse</h2>
          <p>Kjeldsbergveien 2, 9513 Alta</p>
        </div>
        <div className="contact-detail">
          <Link to="/Form">Tilbakemelding skjema</Link>
        </div>
        
      </div>
    </div>
    <footer className="App-footer2">
          <div className="footer-content">
            <div className="App-footer-left">
              <p>KONTAKT OSS: (+47) 46942484 | amdevelopment@aihr.no</p>
            </div>
            <div className="App-footer-center">
              <img src={logo} className="App-logo-footer" alt="logo" />
            </div>
            <div className="App-footer-right">
              <p>© 2024 Am-Development AS</p>
              <p>Organisasjonsnummer: 933 826 759</p>
            </div>
          </div>
        </footer>
    </div>
  );
}

export default Contact;
