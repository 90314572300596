// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDGbABbjTVMwKi06cST9rb_tzixe41KMt8",
  authDomain: "aihr-d0ada.firebaseapp.com",
  projectId: "aihr-d0ada",
  storageBucket: "aihr-d0ada.appspot.com",
  messagingSenderId: "44659104993",
  appId: "1:44659104993:web:1e1ab505adbe240e1e6ba0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const db = getFirestore(app);

export default db;
