import React, { useState } from 'react';
import './ShoppingCart.css';
import Invoice from './Invoice'; // Import the updated Invoice component

function ShoppingCart() {
  const availableLicenses = [
    { id: 1, name: 'KIPA-lisens 1 år', price: 1200 },
    { id: 2, name: 'KIPA-student lisens 1 år', price: 1200 },
    { id: 3, name: 'AI-HR-lisens 1 år', price: 1200 },
    { id: 4, name: 'KIPA-lisens 2 måneder', price: 200 },
    { id: 5, name: 'KIPA-student lisens 2 måneder', price: 200 },
    { id: 6, name: 'AI-HR-lisens 2 måneder', price: 200 }
  ];

  const [cart, setCart] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [orderDetails, setOrderDetails] = useState({ orderNumber: '', orderDate: '' });

  
  // State for buyer information
  const [buyerInfo, setBuyerInfo] = useState({
    name: '',
    address: '',
    orgNumber: '',
    epost: ''
  });

  // State for highlighting invalid fields
  const [invalidFields, setInvalidFields] = useState({
    name: false,
    address: false,
    orgNumber: false,
    epost: false
  });

  // Function to add license to cart or increase quantity if already in cart
  const addToCart = (license) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex((item) => item.id === license.id);

      if (existingItemIndex !== -1) {
        // If the item is already in the cart, increase the quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += 1;
        return updatedCart;
      } else {
        // Otherwise, add the item to the cart with quantity 1
        return [...prevCart, { ...license, quantity: 1 }];
      }
    });
  };

  // Increase the quantity of a license in the cart
  const increaseQuantity = (licenseId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === licenseId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  // Decrease the quantity of a license in the cart
  const decreaseQuantity = (licenseId) => {
    setCart((prevCart) => {
      return prevCart
        .map((item) => {
          if (item.id === licenseId && item.quantity > 1) {
            return { ...item, quantity: item.quantity - 1 };
          }
          return item;
        })
        .filter((item) => item.quantity > 0); // Remove the item if quantity becomes 0
    });
  };

  // Remove the license from the cart completely
  const removeFromCart = (licenseId) => {
    setCart(cart.filter((item) => item.id !== licenseId));
  };

  // Calculate the total price
  const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);

  // Function to validate email format
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const generateInvoice = () => {
    if (isBuyerInfoComplete()) {
      const licenseNames = cart
        .map(item => `${item.name.toLowerCase().replace(/\s+/g, '-')}:${item.quantity}`)
        .join(',');

      // Concatenate all the quantities into a single string
      const licenseAmounts = cart
        .map(item => item.quantity)
        .join(',');
  
      // Build the URL with dynamic values from the form and cart
      const url = `https://sleepy-hamlet-15893-b0b9da7c499e.herokuapp.com/buy?name=${encodeURIComponent(buyerInfo.name)}&adress=${encodeURIComponent(buyerInfo.address)}&orgnr=${encodeURIComponent(buyerInfo.orgNumber)}&to=${encodeURIComponent(buyerInfo.epost)}&lisens=${licenseNames}&nr=${licenseAmounts}`;
      
      // Send a GET request to the API
      fetch(url, {
        method: 'GET'
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse response as JSON
      })
      .then(data => {
        if (data.message === 'Email sent successfully') {
          // Extract the order number and order date from the response
          const { orderNumber } = data;
  
          // Show the invoice with the order information
          setOrderDetails({ orderNumber }); // Store order details in state
          setShowInvoice(true); // Display the invoice screen
        } else {
          // Handle any other responses
          alert('There was an issue with your order. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('An error occurred while processing your order.');
      });
    } else if (!isValidEmail(buyerInfo.epost)) {
      highlightInvalidFields();
      alert("Vennligst fyll ut en gyldig epost adresse.");
    } else {
      highlightInvalidFields();
      alert("Vennligst fyll ut alle Kundeinformasjon feltene.");
    }
  };

  // Handle input changes for buyer information
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBuyerInfo((prevBuyerInfo) => ({ ...prevBuyerInfo, [name]: value }));

    // Reset invalid field highlight when user types
    setInvalidFields((prevInvalidFields) => ({
      ...prevInvalidFields,
      [name]: false // Reset the specific field being updated
    }));

    // Check for valid email format if the field is email
    if (name === 'epost' && !isValidEmail(value)) {
      setInvalidFields((prevInvalidFields) => ({
        ...prevInvalidFields,
        epost: true // Highlight the email field as invalid
      }));
    }
  };

  // Function to check if all buyer info fields are filled and valid
  const isBuyerInfoComplete = () => {
    return buyerInfo.name && buyerInfo.address && buyerInfo.orgNumber && isValidEmail(buyerInfo.epost);
  };

  // Highlight invalid fields
  const highlightInvalidFields = () => {
    const newInvalidFields = {
      name: !buyerInfo.name,
      address: !buyerInfo.address,
      orgNumber: !buyerInfo.orgNumber,
      epost: !isValidEmail(buyerInfo.epost)
    };
    
    setInvalidFields(newInvalidFields);
  };

  return (
    <div className="shopping-cart-page">
      <div className="cart-content">
        {!showInvoice && (
          <div className="buyer-info-form">
            <h1>Kundeinformasjon</h1>
            <form>
              <label>
                Navn:
                <input
                  type="text"
                  name="name"
                  value={buyerInfo.name}
                  onChange={handleInputChange}
                  placeholder="Skriv inn ditt navn"
                  className={invalidFields.name ? 'invalid' : ''}
                />
              </label>
              <label>
                Adresse:
                <input
                  type="text"
                  name="address"
                  value={buyerInfo.address}
                  onChange={handleInputChange}
                  placeholder="Skriv inn din adresse"
                  className={invalidFields.address ? 'invalid' : ''}
                />
              </label>
              <label>
                Organisasjonsnummer:
                <input
                  type="text"
                  name="orgNumber"
                  value={buyerInfo.orgNumber}
                  onChange={handleInputChange}
                  placeholder="Skriv inn org. nummer"
                  className={invalidFields.orgNumber ? 'invalid' : ''}
                />
              </label>
              <label>
                Epost:
                <input
                  type="text"
                  name="epost"
                  value={buyerInfo.epost}
                  onChange={handleInputChange}
                  placeholder="Skriv inn Epost"
                  className={invalidFields.epost ? 'invalid' : ''}
                />
              </label>
            </form>
          </div>
        )}
        {!showInvoice && (
          <div className="cart-section">
            <h1>Handlekurv</h1>

            <div className="licenses-list">
              <h2>Tilgjengelige lisenser</h2>
              {availableLicenses.map((license) => (
                <div key={license.id} className="license-item">
                  <span>{license.name} - {license.price} Kr</span>
                  <button onClick={() => addToCart(license)}>Legg til i handlekurv</button>
                </div>
              ))}
            </div>

            <div className="cart-items">
              <h2>Din handlekurv</h2>
              {cart.length === 0 ? (
                <p>Handlekurven er tom</p>
              ) : (
                <ul>
                  {cart.map((item, index) => (
                    <li key={index}>
                      {item.name} - {item.price} Kr x {item.quantity}
                      <div className="quantity-controls">
                        <button onClick={() => increaseQuantity(item.id)}>+</button>
                        <button onClick={() => decreaseQuantity(item.id)}>-</button>
                        <button onClick={() => removeFromCart(item.id)}>Fjern</button>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="cart-total">
              <h3>Total: {totalPrice} Kr</h3>
              {cart.length > 0 && (
                <button
                  className="checkout-btn"
                  onClick={generateInvoice}
                >
                  Til Betaling
                </button>
              )}
            </div>
          </div>
        )}
        {showInvoice && <Invoice cart={cart} buyerInfo={buyerInfo} orderDetails={orderDetails} />}
      </div>
    </div>
  );
}

export default ShoppingCart;
